import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import ReactMarkdown from "react-markdown";

import Layout from "../components/Layout";

export const PartenairesPageTemplate = props => {
  const { page } = props;

  return (
    <div className="section partenaires-cover">
      <img className="partenaires-cover-image" src="/img/partenaires-cover.svg" alt="partenaires cover" />
      <div className="content-wrapper small flex-column justc-center alitems-start partenaires-hero">
        <div className="section-title left title-3">{page.frontmatter.title}</div>
      </div>

      <div className="content-wrapper small flex-row justc-space-b alitems-stretch wrap">
        {page.frontmatter.partenaires.map((partenaire, index) => (
          <a key={index} href={partenaire.url} className="partenaire-wrapper" target="_blank" rel="noopener noreferrer">
            <div className="logo-wrapper">
              <img className="partenaire-logo" src={partenaire.logo} alt="logo"/>
            </div>
            <div className="partenaire-content">
              <b>{partenaire.name}</b><br/>
              <ReactMarkdown children={partenaire.description}/>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

const PartenairesPage = ({ data }) => {
  const { markdownRemark: page, footerData, navbarData } = data;
  const {
    frontmatter: {
      seo: { title: seoTitle, description: seoDescription, browserTitle },
    },
  } = page; 

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <title>{browserTitle}</title>
      </Helmet>
      <PartenairesPageTemplate page={page} />
    </Layout>
  );
};

PartenairesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PartenairesPage;

export const partenairesPageQuery = graphql`
  query PartenairesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        partenaires {
          name
          url
          logo
          description
        }
        seo {
          browserTitle
          title
          description
        }
      }
    }
    ...LayoutFragment
  }
`;
